import { Suspense, lazy } from 'react'
import SpinnerComp from 'components/commonComps/SpinnerComp/index'
import { Navigate, Route, Routes } from 'react-router-dom'
import SuspenseWrapper from './SuspenseWrapper'
const SignIn = lazy(() => import('components/auth/signInComp/SignIn'))
const ForgotPassword = lazy(() => import('components/auth/forgotPasswordComp/ForgotPassword'))
const NewPassword = lazy(() => import('components/auth/newPasswordComp/NewPasswordComp'))
const AuthLayout = lazy(() => import('components/commonComps/authLayoutComp/AuthLayout'))

const SetPassword = lazy(() => import('components/auth/setPasswordComp/SetPasswordComp'))

export default function AuthRoutes() {
     return (
          <Suspense fallback={<SpinnerComp />}>
               <Routes>
                    <Route
                         path="/"
                         element={
                              <Suspense fallback={<SpinnerComp />}>
                                   <Navigate to={'/auth/sign-in'} />
                              </Suspense>
                         }
                    />
                    <Route path="/" element={<SuspenseWrapper component={<AuthLayout />} />}>
                         <Route
                              path={'/sign-in'}
                              key={'sign-in'}
                              element={<SuspenseWrapper component={<SignIn />} />}
                         />
                         <Route
                              path={'/forgot-password'}
                              key={'forgot-password'}
                              element={<SuspenseWrapper component={<ForgotPassword />} />}
                         />
                         <Route
                              path={'/new-password'}
                              key={'new-password'}
                              element={<SuspenseWrapper component={<NewPassword />} />}
                         />
                         <Route
                              path={'/set-password'}
                              key={'set-password'}
                              element={<SuspenseWrapper component={<SetPassword />} />}
                         />
                    </Route>
               </Routes>
          </Suspense>
     )
}
