import { LazyExoticComponent, lazy } from 'react'
const Dashboard = lazy(() => import('components/dashBoardComp/index'))
const JobSeeker = lazy(() => import('components/jobSeekerComp/index'))
const ViewJobSeeker = lazy(() => import('components/jobSeekerComp/components/ViewJobSeeker'))
const Employer = lazy(() => import('components/employerComp/index'))
const ViewEmployer = lazy(() => import('components/employerComp/components/ViewEmployer'))
const Jobs = lazy(() => import('components/jobsComp/index'))
const ViewJobs = lazy(() => import('components/jobsComp/components/ViewJobsComp'))

export interface RouteTypes {
     path: string
     name: string
     routeKey: string
     element: LazyExoticComponent<() => JSX.Element>
}

const mainRoutes: RouteTypes[] = [
     {
          path: '/dashboard',
          name: 'Dashboard',
          routeKey: 'home',
          element: Dashboard
     },
     {
          path: '/about',
          name: 'About',
          routeKey: 'about',
          element: Dashboard
     },
     {
          path: '/jobSeeker/:jsStatus',
          name: 'Job seeker',
          routeKey: 'jobSeeker',
          element: JobSeeker
     },
     {
          path: '/jobSeeker/:jsStatus/:jobSeekerId',
          name: 'View job seeker',
          routeKey: 'viewJobSeeker',
          element: ViewJobSeeker
     },
     {
          path: '/employer/:employerStatus',
          name: 'Employer',
          routeKey: 'employer',
          element: Employer
     },
     {
          path: '/employer/:empStatus/:employerId',
          name: 'View employer',
          routeKey: 'viewEmployer',
          element: ViewEmployer
     },
     {
          path: '/jobs/:jobStatus',
          name: 'Jobs',
          routeKey: 'jobs',
          element: Jobs
     },
     {
          path: '/jobs/:jobStatus/:jobId',
          name: 'View job',
          routeKey: 'viewJob',
          element: ViewJobs
     }
]

export { mainRoutes }
