import React, { useContext } from 'react'
import type { MenuProps } from 'antd'
import { DashboardOutlined, IdcardOutlined, UserOutlined, SolutionOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { empMenus, jobsMenus, jsMenus } from 'utils/constants'
import { GlobalContext } from 'components/commonHelpers/GlobalContext.tsx/globalStates'

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuProps['items']) {
     return {
          key,
          icon,
          children,
          label
     }
}

export const items = (): MenuProps['items'] => {
     const { setEmpPagination, setEmpSearch, setJsPagination, setJsSearch, setJobsPagination, setJobsSearch } =
          useContext(GlobalContext)

     return [
          getItem(<Link to={'/dashboard'}>DashBoard</Link>, 'dashboard', <DashboardOutlined />),
          getItem(
               'Employer',
               'employer',
               <IdcardOutlined />,
               empMenus?.map((item) => {
                    return getItem(
                         <Link
                              onClick={() => {
                                   setEmpSearch('')
                                   setEmpPagination({ current: 1, total: 0, pageSize: 10 })
                              }}
                              to={item?.path}>
                              {item?.label}
                         </Link>,
                         item?.key
                    )
               })
          ),
          getItem(
               'Job seeker',
               'jobseeker',
               <UserOutlined />,
               jsMenus?.map((item) => {
                    return getItem(
                         <Link
                              onClick={() => {
                                   setJsSearch('')
                                   setJsPagination({ current: 1, total: 0, pageSize: 10 })
                              }}
                              to={item?.path}>
                              {item?.label}
                         </Link>,
                         item?.key
                    )
               })
          ),

          // getItem('Customer support', 'customersupport', <CommentOutlined />, [
          //      getItem(<Link to={'/customersupport/open'}>Open</Link>, 'cs-open'),
          //      getItem(<Link to={'customersupport/closed'}>Closed</Link>, 'cs-closed')
          // ]),
          getItem(
               'Jobs',
               'jobs',
               <SolutionOutlined />,
               jobsMenus?.map((item) => {
                    return getItem(
                         <Link
                              onClick={() => {
                                   setJobsSearch('')
                                   setJobsPagination({ current: 1, total: 0, pageSize: 10 })
                              }}
                              to={item?.path}>
                              {item?.label}
                         </Link>,
                         item?.key
                    )
               })
          )
     ]
}
