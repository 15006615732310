import React, { useEffect, useRef, useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import { items } from './MenuItems'
import siderLogo from 'assets/images/siderLogo.png'
import sideBarStyles from './sideBar.module.scss'
import { useParams } from 'react-router-dom'
import { activeKey } from './ActiveKeyCheck'

const { Sider } = Layout

export default function SideBar() {
     const [selectedKey, setSelectedKey] = useState<string>('')
     const path = useRef<string | any>(window.location.pathname.split('/'))
     const [openMenuKey, setOpenMenuKey] = useState<string | null>('')
     const Parameters = useParams()

     function sidebarActive(key: string) {
          setSelectedKey(key)
     }
     const onClick: MenuProps['onClick'] = (e) => {
          sidebarActive(e.key)
     }
     const onClickSubMenu = (e: string[]) => {
          const activeSubMenu = e.length - 1

          setOpenMenuKey(e[activeSubMenu])
     }

     useEffect(() => {
          const lastSegment = path.current[path.current.length - 1]
          activeKey(sidebarActive, lastSegment, Parameters, setOpenMenuKey)
     }, [path.current])

     // function Dropdown() {
     //      const [isOpen, setIsOpen] = useState<boolean>(false)
     //      const toggleDropdown = () => {
     //           setIsOpen(!isOpen);
     //      }

     //      useEffect(() => {
     //           const isOpenStored = localStorage.getItem('dropdownIsOpen');
     //           if (isOpenStored === 'true') {
     //                setIsOpen(true);
     //           } else {
     //                setIsOpen(false);
     //           }
     //      }, [])
     // }

     return (
          <Sider
               width={250}
               breakpoint="lg"
               collapsedWidth="0"
               className={sideBarStyles.sider}
               // onBreakpoint={(broken) => {
               //      console.log(broken)
               // }}
               // onCollapse={(collapsed, type) => {
               //      console.log(collapsed, type)
               // }}
          >
               <div className={sideBarStyles.siderLogo}>
                    <img src={siderLogo} alt="logo" />
               </div>
               <Menu
                    onClick={onClick}
                    multiple={false}
                    openKeys={[openMenuKey ?? '']}
                    onOpenChange={onClickSubMenu}
                    selectedKeys={[selectedKey]}
                    theme="dark"
                    mode="inline"
                    items={items()}
               />
          </Sider>
     )
}
