import React, { Suspense } from 'react'
import SpinnerComp from 'components/commonComps/SpinnerComp/index'

type Props = {
     component: JSX.Element
}

export default function SuspenseWrapper({ component }: Props) {
     function Spinner() {
          return <SpinnerComp />
     }

     return <Suspense fallback={<Spinner />}>{component}</Suspense>
}
