import { createContext } from 'react'
import { paginationDataType } from 'types/paginationTypes'
interface GlobalContextType {
     value: boolean
     setValue: (value: boolean) => void
     empSearch: string
     setEmpSearch: (empSearch: string) => void
     empPagination: paginationDataType
     setEmpPagination: (empPagination: paginationDataType) => void
     jsSearch: string
     setJsSearch: (jsSearch: string) => void
     jsPagination: paginationDataType
     setJsPagination: (jsPagination: paginationDataType) => void
     jobsSearch: string
     setJobsSearch: (jobsSearch: string) => void
     jobsPagination: paginationDataType
     setJobsPagination: (jobsPagination: paginationDataType) => void
}

export const GlobalContext = createContext<GlobalContextType>({
     value: false,
     setValue: (value: boolean) => value,
     empSearch: '',
     setEmpSearch: (empSearch: string) => empSearch,
     empPagination: { current: 1, pageSize: 10, total: 0 },
     setEmpPagination: (empPagination: paginationDataType) => empPagination,
     jsSearch: '',
     setJsSearch: (jsSearch: string) => jsSearch,
     jsPagination: { current: 1, pageSize: 10, total: 0 },
     setJsPagination: (jsPagination: paginationDataType) => jsPagination,
     jobsSearch: '',
     setJobsSearch: (jobsSearch: string) => jobsSearch,
     jobsPagination: { current: 1, pageSize: 10, total: 0 },
     setJobsPagination: (jobsPagination: paginationDataType) => jobsPagination
})
