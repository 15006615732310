import { Suspense, useState } from 'react'
import './App.scss'
import './components/commonComps/commonStyles/commonStyles.scss'
import AntDesignConfigProvider from 'components/commonHelpers/antDesign/AntDesignConfigProvider'
import { GlobalContext } from 'components/commonHelpers/GlobalContext.tsx/globalStates'
import SpinnerComp from 'components/commonComps/SpinnerComp/index'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProtectedRoutes from 'utils/routing/ProtectedRoutes'
import AuthRoutes from 'utils/routing/AuthRoutes'
import { Auth } from 'aws-amplify'
import { awsConfig } from 'utils/awsConfiguration/aws.Config'
import { paginationDataType } from 'types/paginationTypes'

function App() {
     const [value, setValue] = useState<boolean>(false)
     const [empSearch, setEmpSearch] = useState<string>('')
     const [empPagination, setEmpPagination] = useState<paginationDataType>({ current: 1, pageSize: 10, total: 0 })
     const [jsSearch, setJsSearch] = useState<string>('')
     const [jsPagination, setJsPagination] = useState<paginationDataType>({ current: 1, pageSize: 10, total: 0 })
     const [jobsSearch, setJobsSearch] = useState<string>('')
     const [jobsPagination, setJobsPagination] = useState<paginationDataType>({ current: 1, pageSize: 10, total: 0 })

     Auth.configure({
          region: awsConfig.aws_cognito_region,
          userPoolId: awsConfig.aws_user_pools_id,
          userPoolWebClientId: awsConfig.aws_user_pools_web_client_id
     })

     return (
          <AntDesignConfigProvider>
               <GlobalContext.Provider
                    value={{
                         value,
                         setValue,
                         empSearch,
                         setEmpSearch,
                         empPagination,
                         setEmpPagination,
                         jsSearch,
                         setJsSearch,
                         jsPagination,
                         setJsPagination,
                         jobsSearch,
                         setJobsSearch,
                         jobsPagination,
                         setJobsPagination
                    }}>
                    <BrowserRouter>
                         <Routes>
                              <Route
                                   path="/auth/*"
                                   element={
                                        <Suspense fallback={<SpinnerComp />}>
                                             <AuthRoutes />
                                        </Suspense>
                                   }
                              />
                              <Route
                                   path="/*"
                                   element={
                                        <Suspense fallback={<SpinnerComp />}>
                                             <Suspense fallback={<SpinnerComp />}>
                                                  <ProtectedRoutes />
                                             </Suspense>
                                        </Suspense>
                                   }
                              />
                         </Routes>
                    </BrowserRouter>
               </GlobalContext.Provider>
          </AntDesignConfigProvider>
     )
}

export default App
