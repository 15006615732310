import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'antd/dist/reset.css'
import './index.css'
import { QueryClientProvider, QueryClient } from 'react-query'

const queryClient = new QueryClient({
     defaultOptions: {
          queries: {
               refetchOnWindowFocus: false,
               retry: false
          }
     }
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
     <React.StrictMode>
          <QueryClientProvider client={queryClient}>
               <App />
          </QueryClientProvider>
     </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
