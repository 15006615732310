import React, { useState } from 'react'
import navbarStyles from './navbar.module.scss'
import { UserOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import AdminProfileModal from './adminProfileModals/AdminProfileModal'
import PasswordModal from './adminProfileModals/PasswordModal'

export default function Navbar() {
     const [openModal, setOpenModal] = useState<boolean>(false)
     const [openPassModal, setOpenPassModal] = useState<boolean>(false)
     const showModal = () => {
          setOpenModal(true)
     }

     return (
          <>
               <div className={navbarStyles.header}>
                    <div className={navbarStyles.version}>Version {process.env.REACT_APP_VERSION}</div>
                    <div className={navbarStyles.headerActions}>
                         <div className={navbarStyles.profile}>
                              <Button type="text" onClick={showModal} className="noBackGround">
                                   Admin
                                   <UserOutlined style={{ fontSize: 20 }}></UserOutlined>
                              </Button>
                         </div>
                    </div>
               </div>
               <AdminProfileModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setOpenPassModal={setOpenPassModal}
               />
               <PasswordModal openPassModal={openPassModal} setOpenPassModal={setOpenPassModal} />
          </>
     )
}
