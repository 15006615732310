export const empMenus = [
     {
          key: 'emp-pending',
          path: '/employer/pending',
          label: 'Pending'
     },
     {
          key: 'emp-approved',
          path: '/employer/approved',
          label: 'Approved'
     },
     {
          key: 'emp-rejected',
          path: '/employer/rejected',
          label: 'Rejected'
     },
     {
          key: 'emp-blocked',
          path: '/employer/blocked',
          label: 'Blocked'
     },
     {
          key: 'emp-deleted',
          path: '/employer/deleted',
          label: 'Deleted'
     }
]

export const jsMenus = [
     {
          key: 'js-pending',
          path: '/jobseeker/pending',
          label: 'Pending'
     },
     {
          key: 'js-approved',
          path: '/jobseeker/approved',
          label: 'Approved'
     },
     {
          key: 'js-rejected',
          path: '/jobseeker/rejected',
          label: 'Rejected'
     },
     {
          key: 'js-blocked',
          path: '/jobseeker/blocked',
          label: 'Blocked'
     },
     {
          key: 'js-deleted',
          path: '/jobseeker/deleted',
          label: 'Deleted'
     }
]

export const jobsMenus = [
     {
          key: 'onGoing',
          path: '/jobs/onGoing',
          label: 'On going'
     },
     {
          key: 'hired',
          path: '/jobs/hired',
          label: 'Hired'
     },
     {
          key: 'closed',
          path: '/jobs/closed',
          label: 'Closed'
     }
]
