import { LockOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Button, Col, Image, Modal, Popconfirm, Row, message } from 'antd'
import navbarStyles from '../navbar.module.scss'
import React from 'react'
import Admin from '../../../../assets/images/noProfile.png'
import { useNavigate } from 'react-router'
import { Auth } from 'aws-amplify'

type Props = {
    openModal: boolean
    setOpenModal: (openModal: boolean) => void
    setOpenPassModal: (openPassModal: boolean) => void
}
export default function AdminProfileModal({ openModal, setOpenModal, setOpenPassModal }: Props) {
    const handleCancel = () => {
        setOpenModal(false)
    }
    const openPassModal = () => {
        setOpenPassModal(true)
        setOpenModal(false)
    }
    const navigate = useNavigate()
    const logout = async () => {
        try {
            await Auth.signOut()
            localStorage.clear()
            message.success('Logged out successfully')
            navigate('/auth')
            sessionStorage.clear()
            window.location.reload()
        } catch (error) {
            const errorMsg: any = error
            message.error(errorMsg.message)
        }
    }
    return (
        <div>
            <Modal open={openModal} onCancel={handleCancel} centered footer={null} className={navbarStyles.adminProfileModal}>
                <h3>Admin Profile</h3>
                <Row className={navbarStyles.adminProfileRow}>
                    <Image preview={false} src={Admin} ></Image>
                    <Col>
                        <h3>Partimer Admin</h3>
                    </Col>
                </Row>
                <Row className={navbarStyles.adminProfileBtns}>
                    <Col span={24}>
                        <Button className='noBackGround' type="text" onClick={openPassModal}>
                            <LockOutlined style={{ fontSize: 20 }}> </LockOutlined>
                            Change Password
                        </Button>
                    </Col>

                    <Col span={24}>
                        <Popconfirm
                            title="Are you sure you want to logout?"
                            onConfirm={logout}
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ className: 'popBtn' }}
                            cancelButtonProps={{ className: 'popBtn' }}

                        >
                            <Button className='noBackGround' type='text'>
                                <PoweroffOutlined style={{ fontSize: 20, background: 'transparent' }}></PoweroffOutlined>
                                Log out
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Modal>

        </div >
    )
}