import React, { ReactNode } from 'react'
import { ConfigProvider, App } from 'antd'
type Props = {
     children: ReactNode
}
export default function AntDesignConfigProvider({ children }: Props) {
     return (
          <ConfigProvider
               theme={{
                    token: {
                         colorPrimary: '#3456FF',
                         fontFamily: 'Gilroy-Regular'
                    },

                    components: {
                         Form: {
                              labelFontSize: 16,
                              labelColor: '#001320'
                         },
                         Button: {
                              borderRadius: 24,
                              boxShadow: '0px 16px 60px 0px rgba(44, 41, 41, 0.08)',
                              controlHeight: 46,
                              fontSize: 18,
                              fontSizeLG: 17,
                              fontSizeSM: 15,
                              fontSizeXL: 18,
                              fontWeightStrong: 600
                         },
                         Layout: {
                              // bodyBg: '#ffffff'
                         },
                         Input: {
                              borderRadius: 4,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#BABABA'
                         },
                         InputNumber: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#BABABA'
                         },
                         Divider: {
                              colorBgBase: '#BABABA',
                              colorBorder: '#BABABA'
                         },
                         Select: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#BABABA',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000'
                         },
                         DatePicker: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#BABABA',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000'
                         },
                         Checkbox: {
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              borderRadius: 2,
                              colorBorder: '#3456FF45'
                         }
                    }
               }}>
               <App>{children}</App>
          </ConfigProvider>
     )
}
