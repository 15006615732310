import { Modal, Form, Input, Button, message } from 'antd'
import React, { useState } from 'react'
import navbarStyles from '../navbar.module.scss'
import { Auth } from 'aws-amplify'

type Props = {
     openPassModal: boolean
     setOpenPassModal: (openPassModal: boolean) => void
}

export default function PasswordModal({ openPassModal, setOpenPassModal }: Props) {
     const [loading, setLoading] = useState<boolean>(false)
     const [form] = Form.useForm()
     const resetPassword = async (values: any) => {
          setLoading(true)
          try {
               const user = await Auth.currentAuthenticatedUser()
               const data = await Auth.changePassword(user, values.oldPassword, values.confirm)
               console.log(data)
               message.success('Successfully changed password')
               setLoading(false)
               setOpenPassModal(false)
          } catch (err) {
               const error: any = err
               message.error(error.message)
               setLoading(false)
          }
     }
     const handleCancel = () => {
          setOpenPassModal(false)
          form.resetFields()
     }
     return (
          <React.Fragment>
               <Modal
                    open={openPassModal}
                    onCancel={handleCancel}
                    centered
                    footer={null}
                    className={navbarStyles.adminPassModal}>
                    <h3>Change Password</h3>
                    <Form onFinish={resetPassword} form={form} layout="vertical">
                         <Form.Item
                              rules={[{ required: true, message: 'Please input your old password!' }]}
                              name={'oldPassword'}
                              label={'Enter your old password'}
                              hasFeedback>
                              <Input.Password placeholder="Please enter old password" />
                         </Form.Item>
                         <Form.Item
                              rules={[{ required: true, message: 'Please input your new password!' }]}
                              name={'newPassword'}
                              label={'Enter your new password'}
                              hasFeedback>
                              <Input.Password placeholder="Please enter new password" />
                         </Form.Item>
                         <Form.Item
                              name="confirm"
                              label="Confirm your password"
                              dependencies={['newPassword']}
                              hasFeedback
                              rules={[
                                   {
                                        required: true,
                                        message: 'Please confirm your password!'
                                   },
                                   ({ getFieldValue }) => ({
                                        validator(_, value) {
                                             if (!value || getFieldValue('newPassword') === value) {
                                                  return Promise.resolve()
                                             }
                                             return Promise.reject(
                                                  new Error('The new password that you entered do not match!')
                                             )
                                        }
                                   })
                              ]}>
                              <Input.Password placeholder="Please confirm password" />
                         </Form.Item>
                         <div className={navbarStyles.signInFooter}>
                              <Form.Item className={navbarStyles.btns}>
                                   <Button onClick={handleCancel} className={navbarStyles.discardBtn}>
                                        Discard
                                   </Button>
                                   <Button
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className={navbarStyles.submitBtn}>
                                        Submit
                                   </Button>
                              </Form.Item>
                         </div>
                    </Form>
               </Modal>
          </React.Fragment>
     )
}
