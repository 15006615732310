import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import SpinnerComp from 'components/commonComps/SpinnerComp/index'
import SuspenseWrapper from './SuspenseWrapper'
import Layout from 'components/commonComps/layoutComp/Layout'
import { RouteTypes, mainRoutes } from './routes'
import { isLoggedIn } from 'awsGetToken'

export default function ProtectedRoutes() {
     const navigate = useNavigate()
     useEffect(() => {
          async function getSessionState() {
               const _loggedIn = await isLoggedIn()
               if (!_loggedIn) {
                    navigate('/auth')
               }
          }
          getSessionState()
     }, [])
     return (
          <Suspense fallback={<SpinnerComp />}>
               <Routes>
                    <Route
                         path="/"
                         element={
                              <Suspense fallback={<SpinnerComp />}>
                                   <Navigate to={'/dashboard'} />
                              </Suspense>
                         }
                    />
                    <Route path="/" element={<SuspenseWrapper component={<Layout />} />}>
                         {mainRoutes.map((route: RouteTypes, ind: number) => {
                              return (
                                   <Route
                                        path={route.path}
                                        key={route.routeKey + ind}
                                        element={<SuspenseWrapper component={<route.element />} />}
                                   />
                              )
                         })}
                    </Route>
               </Routes>
          </Suspense>
     )
}
