import { Params } from 'react-router-dom'

export function activeKey(
     sidebarActive: (key: string) => void,
     lastsegment: string,
     Parameters: Readonly<Params<string>>,
     setOpenMenuKey: (key: string) => void
) {
     const { employerId, jobSeekerId, empStatus, jobId, jobStatus, employerStatus, jsStatus } = Parameters

     switch (lastsegment) {
          case 'dashboard':
               sidebarActive('dashboard')
               break
          case employerId:
               sidebarActive(`emp-${empStatus}`)
               setOpenMenuKey('employer')
               break
          case jobSeekerId:
               sidebarActive(`js-${jsStatus}`)
               setOpenMenuKey('jobseeker')
               break
          case jobId:
               sidebarActive(`${jobStatus}`)
               setOpenMenuKey('jobs')
               break
          case employerStatus:
               sidebarActive(`emp-${employerStatus}`)
               setOpenMenuKey('employer')
               break
          case jsStatus:
               sidebarActive(`js-${jsStatus}`)
               setOpenMenuKey('jobseeker')
               break
          case jobStatus:
               sidebarActive(`${jobStatus}`)
               setOpenMenuKey('jobs')
               break
     }
}
