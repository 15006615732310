import React from 'react'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import SideBar from '../sideBarComp/SideBar'
import layoutStyles from './layout.module.scss'
import Navbar from '../navbarComp/Navbar'

const LayoutComp: React.FC = () => {
     return (
          <Layout>
               <SideBar />
               <Layout>
                    <div className={layoutStyles.navbar}>
                         <Navbar />
                    </div>
                    <div className={layoutStyles.content}>
                         <Outlet />
                    </div>
               </Layout>
          </Layout>
     )
}

export default LayoutComp
